
import { Options, mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import WithModel from '@/components/standard/formFieldPlugin/mixins/withModel';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class selectGenders extends mixins(WithModel) {

    declare localValue: OM.GarmentPropertyLayer;
    @Prop() garmentConfiguration: OM.MyGarmentConfigurationsVM; 
    @Prop() index: number; 

    created() {
    }

    get genders(){
        if(!this.garmentConfiguration || this.garmentConfiguration.garmentLayersPropertyValues[this.index].propertyValues.length == 0)
            return [];

        var tempGenders = this.garmentConfiguration.garmentLayersPropertyValues[this.index].propertyValues.map( x => x.gender);

        var filteredGenders = [...new Set(tempGenders)];

        return filteredGenders.map( x => {
            return {
                identifier: x,
                text: x
            }
        })
    }

    genderChanged(newGender: string){
        if(!newGender || this.localValue.gender == newGender)
            return;

        this.localValue.gender = newGender;
        this.localValue.properties = [];

        this.$emit('valueUpdated', newGender);
    }
}
