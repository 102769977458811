import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fw-bold mb-4"
}
const _hoisted_2 = { class: "row mt-3" }
const _hoisted_3 = { class: "col-sm-6" }
const _hoisted_4 = { class: "col-sm-6" }
const _hoisted_5 = {
  key: 1,
  class: "row mt-3"
}
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "d-flex justify-content-end mt-3" }
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_genders = _resolveComponent("select-genders")!
  const _component_form_field = _resolveComponent("form-field")!
  const _component_select_garment_properties = _resolveComponent("select-garment-properties")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.estimationBaseInformation.garmentPropertyLayers, (garmentLayer, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "mb-4",
        key: index
      }, [
        (_ctx.estimationBaseInformation.garmentPropertyLayers.length > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("h4", null, _toDisplayString(garmentLayer.garmentLayerName), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createBlock(_component_select_genders, {
              modelValue: garmentLayer,
              index: index,
              garmentConfiguration: _ctx.garmentConfiguration,
              key: 'gender_' + garmentLayer.garmentLayerName + '_' + index,
              "onUpdate:modelValue": ($event: any) => (_ctx.estimationBaseInformation[index] = $event),
              onValueUpdated: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateGenderForBothGarmentLayer($event)))
            }, null, 8, ["modelValue", "index", "garmentConfiguration", "onUpdate:modelValue"]))
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_form_field, {
              type: "number",
              labelKey: "Wastage Percentage",
              labelValue: "Wastage Percentage",
              placeholderKey: "Wastage Percentage",
              placeholderValue: "Wastage Percentage",
              modelValue: _ctx.bothWastagePercentage,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.bothWastagePercentage) = $event))
            }, null, 8, ["modelValue"])
          ])
        ]),
        (garmentLayer.gender)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(), _createBlock(_component_select_garment_properties, {
                  modelValue: garmentLayer,
                  index: index,
                  key: 'prop' + garmentLayer.garmentLayerName + '_' + index + '_' + garmentLayer.gender,
                  garmentConfiguration: _ctx.garmentConfiguration,
                  "onUpdate:modelValue": ($event: any) => (_ctx.estimationBaseInformation[index] = $event),
                  onEUSizeChanged: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateEUSizeForBothGarmentLayer($event)))
                }, null, 8, ["modelValue", "index", "garmentConfiguration", "onUpdate:modelValue"]))
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_localized_text, {
                localizedKey: "You have to select gender",
                text: "You have to select gender"
              })
            ]))
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        class: "btn btn-tertiary",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.editBaseInformation && _ctx.editBaseInformation(...args))),
        disabled: _ctx.disabled
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Continue",
          text: "Continue"
        })
      ], 8, _hoisted_9)
    ])
  ]))
}