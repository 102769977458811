
import { Options, Vue, mixins } from 'vue-class-component';
import WithModel from '@/components/standard/formFieldPlugin/mixins/withModel';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { nextTick } from 'vue';

@Options({
    components: {
    }
})
export default class selectGarmentProperties extends mixins(WithModel) {

    declare localValue: OM.GarmentPropertyLayer;
    @Prop() index: number; 
    @Prop() garmentConfiguration: OM.MyGarmentConfigurationsVM; 
    
    localGarmentConfiguration: OM.MyGarmentConfigurationsVM = new OM.MyGarmentConfigurationsVM();
    updateListCheck: number = 0;
    
    created() {
        this.localGarmentConfiguration = {...this.garmentConfiguration};
        this.changedFilteredValues();
    }

    @Watch("filteredValues", {deep: true})
    changedFilteredValues(){
        // this.localGarmentConfiguration.garmentLayersPropertyValues[this.index].propertyValues = this.garmentConfiguration.garmentLayersPropertyValues[this.index].propertyValues.filter( x => {
        //     return x.gender == this.localValue.gender
        // })

        this.filteredValues.forEach(filteredValue => {
            var found = this.localValue.properties.find( x => x.propertyName == filteredValue.propertyName);
            
            if(found)
                return;

            var model = new OM.GarmentProperty();
            model.propertyName = filteredValue.propertyName;
            model.propertyValue = "";

            this.localValue.properties.push(model);
        });

        this.updateListCheck = Math.random();
    }

    get filteredValues(){
        if(!this.localGarmentConfiguration.garmentLayersPropertyValues[this.index])
            return [];

        return this.localGarmentConfiguration.garmentLayersPropertyValues[this.index].propertyValues.filter( x => x.gender == this.localValue.gender);
    }

    filteredPropertyValues(propertyName: string){
        return this.filteredValues.filter( x => {
            if(x.propertyName == propertyName)
                return x;
        })
        .map( x => x.values)
        .reduce((a, b) => { return a.concat(b); }, [])
        .map( x => {
            return {
                identifier: x.label,
                text: x.label
            }
        })
    }

    valueChanged(propertyValue: string, propertyName: string){
        if(!propertyValue)
            return;

        var filteredValue = this.filteredValues.find( x => x.propertyName == propertyName);

        var values = filteredValue.values;
        var value = values.find( x => x.label == propertyValue).value;
        
        var found = this.localValue.properties.find( x => x.propertyName == propertyName);
        found.sqmValue = value;
        found.propertyValue = propertyValue;

        if(propertyName.toLocaleLowerCase().indexOf("eu") > -1)
            this.$emit('EUSizeChanged', propertyValue)
    }

    getTotal(index: number){
        var total = 0;

        for(var i = 0; i <= index; i++){
            var garmenProperty = this.localValue.properties[i];

            if(garmenProperty.sqmValue.isPercentage){
                total = total + total / 100 * garmenProperty.sqmValue.value;
            } else {
                total = total + garmenProperty.sqmValue.value;
            }
        }

        return total.toFixed(4);
    }
}
