import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field = _resolveComponent("form-field")!

  return (_openBlock(), _createBlock(_component_form_field, {
    type: "select",
    labelKey: "Gender",
    labelValue: "Gender",
    placeholderKey: "Gender",
    placeholderValue: "Gender",
    list: _ctx.genders,
    modelValue: _ctx.localValue.gender,
    "onUpdate:modelValue": _ctx.genderChanged
  }, null, 8, ["list", "modelValue", "onUpdate:modelValue"]))
}