
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { EstimationClient, GarmentClient, GarmentDefaultDataClient } from '@/services/Services';
import selectGenders from '../../../components/selectGenders.vue';
import SelectGarmentProperties from '../../../components/selectGarmentProperties.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {
        selectGenders,
        SelectGarmentProperties
    }
})
export default class Editbaseinformation extends Vue {

    @Prop() estimationIdentifier: string;
    @Prop() garmentConfigurationIdentifier: string;
    @Prop() saveToGarmentConfiguration: boolean;

    estimationBaseInformation: OM.EstimationBaseInformationVM = new OM.EstimationBaseInformationVM();
    garmentConfiguration: OM.MyGarmentConfigurationsVM = new OM.MyGarmentConfigurationsVM();
    bothWastagePercentage: number = 0;
    
    created() {
        Promise.all([
            store.state.estimationClient.getBaseInformation(this.estimationIdentifier),
            GarmentClient.getMyGarmentConfigurationsVM(this.garmentConfigurationIdentifier),
        ])
        .then( xs => {
            this.estimationBaseInformation = xs[0];
            this.garmentConfiguration = xs[1];

            var firstGarmentLayer = this.estimationBaseInformation.garmentPropertyLayers[0];
            this.bothWastagePercentage = firstGarmentLayer.wastagePercentage;
        })
    }

    updateGenderForBothGarmentLayer(event: string) {
        if(this.estimationBaseInformation.garmentPropertyLayers.length <= 1)
            return;
        
        for(var i = 0; i < this.estimationBaseInformation.garmentPropertyLayers.length; i++) {
            this.estimationBaseInformation[i] = event;
            this.estimationBaseInformation.garmentPropertyLayers[i].gender = event;
            this.estimationBaseInformation.garmentPropertyLayers[i].properties = [];
        }
    }
    updateEUSizeForBothGarmentLayer(event: string) {
        if(this.estimationBaseInformation.garmentPropertyLayers.length <= 1)
            return;
        
        for(var i = 0; i < this.estimationBaseInformation.garmentPropertyLayers.length; i++) {
            var existItem = this.estimationBaseInformation.garmentPropertyLayers[i].properties.find(x => x.propertyName.toLocaleLowerCase().indexOf("eu") > -1);
            var existValue = this.garmentConfiguration.garmentLayersPropertyValues[i].propertyValues.find(x => x.values.find(y => y.label == event));
            if(existItem && existValue)
                existItem.propertyValue = event;
        }
    }

    get disabled(){
        var result = true;

        for(var i = 0; i < this.estimationBaseInformation.garmentPropertyLayers.length; i++){
            var el = this.estimationBaseInformation.garmentPropertyLayers[i];
             
            if(el.gender){
                //Prendo le propertyname con quel gender
                var genderPropertyName = this.garmentConfiguration.garmentLayersPropertyValues[i].propertyValues
                    .filter( x => x.gender == el.gender)
                    .map( x => x.propertyName);
                //Distinct
                genderPropertyName = [...new Set(genderPropertyName)];
                
                var baseInformationPropertySet: OM.GarmentProperty[] = [];
                
                this.estimationBaseInformation.garmentPropertyLayers.forEach( x => {
                    var result = x.properties.filter( x => {
                        return x.propertyValue
                    });
    
                    baseInformationPropertySet.push(...baseInformationPropertySet)
                })
    
                //Controllo che abbia messo tutte le property
                var propertiesSet = genderPropertyName.length == baseInformationPropertySet.length;

                if(!propertiesSet)
                    result = propertiesSet;
            }
        }

        return result
    }

    editBaseInformation(){
        var editBaseInformationVM: OM.EditEstimationBaseInformationVM = {
            identifier: this.estimationBaseInformation.identifier,
            garmentPropertyLayers: this.estimationBaseInformation.garmentPropertyLayers
        };

        store.state.estimationClient.editBaseInformation(editBaseInformationVM)
        .then( x => {
            if(this.saveToGarmentConfiguration)
                this.$router.push("/garmentDefaultData/rawmaterials/" + this.estimationIdentifier);
            else
                this.$router.push("/calculator/rawmaterials/" + this.estimationIdentifier);
        })
    }

    @Watch('bothWastagePercentage')
    updateWastagePercentage() {
        this.estimationBaseInformation.garmentPropertyLayers.forEach(element => {
            element.wastagePercentage = this.bothWastagePercentage;
        });
    }
}

