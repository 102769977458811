import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "table table-striped" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { colspan: "99" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("table", _hoisted_1, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Property Name",
              text: "Property Name"
            })
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "Property Value",
              text: "Property Value"
            })
          ]),
          _createElementVNode("td", null, [
            _createVNode(_component_localized_text, {
              localizedKey: "SQM",
              text: "SQM"
            })
          ])
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_ctx.localValue.properties.length == 0)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
              _createElementVNode("td", _hoisted_3, [
                _createVNode(_component_localized_text, {
                  localizedKey: "No Elements Found",
                  text: "No Elements Found"
                })
              ])
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localValue.properties, (item, i) => {
          return (_openBlock(), _createElementBlock("tr", { key: i }, [
            _createElementVNode("td", null, _toDisplayString(item.propertyName), 1),
            _createElementVNode("td", null, [
              (_openBlock(), _createBlock(_component_form_field, {
                type: "select",
                placeholderKey: "Value",
                placeholderValue: "Value",
                list: _ctx.filteredPropertyValues(item.propertyName),
                modelValue: item.propertyValue,
                "onUpdate:modelValue": ($event: any) => (_ctx.valueChanged($event, item.propertyName)),
                key: _ctx.updateListCheck
              }, null, 8, ["list", "modelValue", "onUpdate:modelValue"]))
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("span", null, _toDisplayString(_ctx.getTotal(i)), 1)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}